import { BaseEndpoint } from "./Base"

export default class FormSelectionProcessSubscriptionQuestionEndpoint extends BaseEndpoint {
  static get selector() {
    return "formSelectionProcessSubscriptions.questions"
  }

  index({ formId, params = {}, options = {} } = {}) {
    this.verifyData("index", { formId })

    return this.action({
      method:  "get",
      url:     `/form/selection_process/subscriptions/${formId}/questions`,
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }

  create({ formId, params = {}, options = {} } = {}) {
    this.verifyData("index", { formId })

    return this.action({
      method:  "post",
      url:     `/form/selection_process/subscriptions/${formId}/questions`,
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }

  update({ formId, questionId, params = {}, options = {} } = {}) {
    this.verifyData("update", { formId, questionId })

    return this.action({
      method:  "patch",
      url:     `/form/selection_process/subscriptions/${formId}/questions/${questionId}`,
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }
}
