import { BaseEndpoint } from "./Base"

export default class FormSelectionProcessSubscriptionEndpoint extends BaseEndpoint {
  static get selector() {
    return "formSelectionProcessSubscriptions"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/form/selection_process_subscriptions",
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/form/selection_process_subscriptions",
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }

  update({ formId, params = {}, options = {} } = {}) {
    this.verifyData("update", { formId })

    return this.action({
      method:  "patch",
      url:     `/form/selection_process_subscriptions/${formId}`,
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }
}
