import { BaseEndpoint } from "./Base"

export default class TeamEndpoint extends BaseEndpoint {
  static get selector() {
    return "teams"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/teams",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
