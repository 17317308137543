import { BaseEndpoint } from "./Base"

export default class UserResendConfirmationEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.resendConfirmation"
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/users/resend_confirmation",
      params,
      options: {
        ...options,

        unversioned: true
      }
    })
  }
}
