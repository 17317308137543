import { BaseEndpoint } from "./Base"

export default class UserPasswordChangeEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.passwordChanges"
  }

  index({ userId, params = {}, options = {} } = {}) {
    this.verifyData("index", { userId })

    return this.action({
      method:  "get",
      url:     `/users/${userId}/password_changes`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
