import { BaseEndpoint } from "./Base"

export default class UserResetPasswordEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.resetPasswords"
  }

  update({ userId, params = {}, options = {} } = {}) {
    this.verifyData("update", { userId })

    return this.action({
      method:  "patch",
      url:     `/users/${userId}/passwords`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
