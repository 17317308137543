import { BaseEndpoint } from "./Base"

export default class UserPasswordEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.password"
  }

  create({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "post",
      url:     "/users/password",
      params,
      options: {
        ...options,

        unversioned: true
      }
    })
  }

  update({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "put",
      url:     "/users/password",
      params,
      options: {
        ...options,

        unversioned: true
      }
    })
  }
}
